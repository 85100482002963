$accent: #f26836;
$primary: #503980;
$secondary: #79b5cc;
$success: #1d9412ff;
$warning: #c78407ff;
$danger: #b83616;

$accent-text-emphasis: shade-color($accent, 60%);
$accent-bg-subtle: tint-color($accent, 80%);
$accent-border-subtle: tint-color($accent, 60%);
$accent-text-emphasis-dark: tint-color($accent, 40%);
$accent-bg-subtle-dark: shade-color($accent, 80%);
$accent-border-subtle-dark: shade-color($accent, 40%);

$border-color:                $accent;
$border-color-translucent:    rgba($accent, .175);

$focus-ring-width:      .25rem;
$focus-ring-opacity:    .25;
$focus-ring-color:      rgba($accent, $focus-ring-opacity);
$focus-ring-blur:       0;
$focus-ring-box-shadow: 0 0 $focus-ring-blur $focus-ring-width $focus-ring-color;

$input-color: $accent;
$input-focus-border-color:              tint-color($accent, 50%);