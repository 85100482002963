@font-face {
    font-family: Gotham;
    src: url(../fonts/Gotham-Light.ttf);
    font-style: normal;
    font-weight: lighter;
}

@font-face {
    font-family: Gotham;
    src: url(../fonts/Gotham-Medium.otf);
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: Gotham;
    src: url(../fonts/Gotham-Bold.ttf);
    font-style: normal;
    font-weight: bold;
}

@font-face {
    font-family: Gotham;
    src: url(../fonts/GothamLightItalic.ttf);
    font-style: italic;
    font-weight: lighter;
}

@font-face {
    font-family: Gotham;
    src: url(../fonts/Gotham-UltraItalic.otf);
    font-style: italic;
    font-weight: normal;
}

@font-face {
    font-family: Gotham;
    src: url(../fonts/GothamBoldItalic.ttf);
    font-style: italic;
    font-weight: bold;
}


@font-face {
    font-family: 'Gotham Thin';
    src: url(../fonts/Gotham-Thin.otf);
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: 'Gotham Thin';
    src: url(../fonts/Gotham-ThinItalic.otf);
    font-style: italic;
    font-weight: normal;
}


@font-face {
    font-family: 'Gotham Book';
    src: url(../fonts/Gotham-Book.otf);
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: 'Gotham Black';
    src: url(../fonts/Gotham-Black.otf);
    font-style: normal;
    font-weight: normal;
}