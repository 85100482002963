@import "./bootstrap.scss";

.urolody-icon {
    background: url('../../public/assets/urology-icon.png');
    background-repeat: no-repeat;
    background-size: 150px 150px;
}

.urolody-icon:hover {
    background: url('../../public/assets/urology-icon-hover.png');
    background-repeat: no-repeat;
    background-size: 150px 150px;
}

.neurology-icon {
    background: url('../../public/assets/neurology-icon.png');
    background-repeat: no-repeat;
    background-size: 150px 150px;
}

.neurology-icon:hover {
    background: url('../../public/assets/neurology-icon-hover.png');
    background-repeat: no-repeat;
    background-size: 150px 150px;
}

.orthopedic-icon {
    background: url('../../public/assets/orthopedic-icon.png');
    background-repeat: no-repeat;
    background-size: 150px 150px;
}

.orthopedic-icon:hover {
    background: url('../../public/assets/orthopedic-icon-hover.png');
    background-repeat: no-repeat;
    background-size: 150px 150px;
}

.cardiologist-icon {
    background: url('../../public/assets/cardiologist-icon.png');
    background-repeat: no-repeat;
    background-size: 150px 150px;
}

.cardiologist-icon:hover {
    background: url('../../public/assets/cardiologist-icon-hover.png');
    background-repeat: no-repeat;
    background-size: 150px 150px;
}

.dentist-icon {
    background: url('../../public/assets/dentist-icon.png');
    background-repeat: no-repeat;
    background-size: 150px 150px;
}

.dentist-icon:hover {
    background: url('../../public/assets/dentist-icon-hover.png');
    background-repeat: no-repeat;
    background-size: 150px 150px;
}

.general-physician-icon {
    background: url('../../public/assets/general-physician-icon.png');
    background-repeat: no-repeat;
    background-size: 150px 150px;
}

.general-physician-icon:hover {
    background: url('../../public/assets/general-physician-icon-hover.png');
    background-repeat: no-repeat;
    background-size: 150px 150px;
}